import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import axios from 'axios';

const GammaProfileComponent = ({ apiEndpoint, chartTitle, symbol }) => {
  const chartRef = useRef(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiEndpoint);
        console.log(`${symbol} Gamma Profile API Response:`, response.data);
        setData(response.data);
      } catch (error) {
        console.error(`Error fetching ${symbol} Gamma Profile data:`, error);
        setError('Failed to fetch data. Please try again later.');
      }
    };

    fetchData();
  }, [apiEndpoint, symbol]);

  useEffect(() => {
    if (!chartRef.current || !data) return;

    const chart = echarts.init(chartRef.current);

    const strikes = data.data.map(item => item.strike);
    const gammaValues = data.data.map(item => item.total_gamma);

    const option = {
      title: {
        text: chartTitle,
        left: 'center',
        textStyle: {
          color: "#eeeeee",
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        formatter: function (params) {
          return `Strike: ${params[0].axisValue}<br/>
                  Total Gamma: ${params[0].data.toLocaleString()}`;
        }
      },
      backgroundColor: "rgba(51,51,51,1)",
      textStyle: {
        color: "#eeeeee",
      },
      xAxis: {
        type: 'category',
        data: strikes,
        name: 'Strike Price',
        nameLocation: 'middle',
        nameGap: 30,
        axisLabel: {
          color: "#eeeeee",
        },
      },
      yAxis: {
        type: 'value',
        name: 'Total Gamma',
        position: 'left',
        axisLine: {
          lineStyle: {
            color: '#91cc75'
          }
        },
        max: 'dataMax',
        axisLabel: {
          formatter: (value) => value.toLocaleString(),
          color: "#eeeeee",
        },
      },
      series: [
        {
          name: 'Total Gamma',
          type: 'bar',
          data: gammaValues,
          itemStyle: {
            color: (params) => {
              return params.data >= 0 ? '#91cc75' : '#ee6666';
            }
          },
        }
      ]
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [data, chartTitle]);

  if (error) return <div className="text-red-500">{error}</div>;
  if (!data) return <div>Loading...</div>;

  return <div ref={chartRef} style={{ width: '100%', height: '650px' }} />;
};

export default GammaProfileComponent;