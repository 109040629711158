import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

const LiquidityComponent = ({ apiEndpoint, chartTitle }) => {
  const [option, setOption] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(apiEndpoint);
        if (res.data !== undefined) {
          const objArray = Object.entries(res.data)[1][1];
          const newOption = {
            backgroundColor: "rgba(51,51,51,1)",
            title: {
              text: chartTitle,
              left: "center",
              textStyle: {
                color: "#eeeeee",
              },
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#6a7985",
                },
              },
            },
            legend: {
              data: ['Balance Sheet', 'RRP', 'Liquidity', 'SPX'],
              orient: "horizontal",
              left: 10,
              textStyle: {
                color: "#eeeeee",
              },
            },
            dataZoom: [{
              type: "slider",
              start: 50,
              end: 100,
            }],
            xAxis: {
              type: "category",
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "Balance Sheet",
                type: "line",
                areaStyle: {},
                encode: {
                  x: "index",
                  y: "Balance",
                },
              },
              {
                name: "RRP",
                type: "line",
                areaStyle: {},
                encode: {
                  y: "RRP",
                },
              },
              {
                name: "Liquidity",
                type: "line",
                areaStyle: {},
                encode: {
                  y: "Liquidity",
                },
              },
              {
                name: "SPX",
                type: "line",
                areaStyle: {},
                encode: {
                  y: "SPX",
                },
              },
            ],
            dataset: {
              source: objArray,
            },
          };
          setOption(newOption);
          setLoading(false);
        }
      } catch (e) {
        setError(`Failed to fetch data: ${e.message}`);
        setLoading(false);
      }
    };

    fetchData();
  }, [apiEndpoint, chartTitle]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="h-full">
      <ReactECharts
        option={option}
        style={{ height: '100%', width: '100%' }}
        opts={{ renderer: 'svg' }}
      />
    </div>
  );
};

export default LiquidityComponent;