import React from 'react';
import AppLayout from './AppLayout';
import Dashboard from './Dashboard';

function App() {
  return (
    <AppLayout>
      <Dashboard />
    </AppLayout>
  );
}

export default App;