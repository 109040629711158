import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import EChartsComponent from './EChartsComponent';
import ExpectedMoveComponent from './ExpectedMoveComponent';
import GammaProfileComponent from './GammaProfileComponent';
import LiquidityComponent from './LiquidityComponent';
import ScannerComponent from './ScannerComponent';

const endpoints = {
  'Cycle': {
    'QQQ 30min': {
      url: process.env.REACT_APP_API_ENDPOINT_QQQ_30MIN,
      title: 'QQQ 30分钟周期'
    },
    'SPY 30min': {
      url: process.env.REACT_APP_API_ENDPOINT_SPY_30MIN,
      title: 'SPY 30分钟周期'
    },
    'QQQ Daily': {
      url: process.env.REACT_APP_API_ENDPOINT_QQQ_DAILY,
      title: 'QQQ 日线周期'
    },
    'SPY Daily': {
      url: process.env.REACT_APP_API_ENDPOINT_SPY_DAILY,
      title: 'SPY 日线周期'
    },
  },
  'Gamma Profile': {
    'SPX': {
      url: `${process.env.REACT_APP_API_BASE_URL}/spx_gamma`,
      title: 'SPX Gamma Profile',
      symbol: 'SPX'
    },
    'QQQ': {
      url: `${process.env.REACT_APP_API_BASE_URL}/ndx_gamma`,
      title: 'QQQ Gamma Profile',
      symbol: 'NDX'
    },
    'RUT': {
      url: `${process.env.REACT_APP_API_BASE_URL}/rut_gamma`,
      title: 'RUT Gamma Profile',
      symbol: 'RUT'
    }
  },
  'Expected Move': {
    'QQQ': {
      url: process.env.REACT_APP_API_ENDPOINT_QQQ_EXPECTED_MOVE,
      title: 'QQQ Expected Move',
      symbol: 'QQQ'
    },
    'SPY': {
      url: process.env.REACT_APP_API_ENDPOINT_SPY_EXPECTED_MOVE,
      title: 'SPY Expected Move',
      symbol: 'SPY'
    },
  },
  'Liquidity': {
    'Market Liquidity': {
      url: `${process.env.REACT_APP_API_BASE_URL}/api/get-fed-liq`,
      title: 'Market Liquidity'
    }
  },
  'Scanner': {
    'Stock Scanner': {
      url: `${process.env.REACT_APP_API_BASE_URL}/stocks/scanner`,
      title: 'Stock Scanner'
    }
  },
  'Live Room': {
    'Trading Room': {
      url: process.env.REACT_APP_LIVE_ROOM_URL,
      title: 'Live Trading Room'
    }
  }
};

const Dashboard = ({ selectedItem }) => {
  const [currentEndpoint, setCurrentEndpoint] = useState(null);

  useEffect(() => {
    const selectedEndpoints = endpoints[selectedItem];
    if (selectedEndpoints) {
      const firstEndpointKey = Object.keys(selectedEndpoints)[0];
      setCurrentEndpoint(selectedEndpoints[firstEndpointKey]);
    }
  }, [selectedItem]);

  const handleEndpointChange = (newEndpoint) => {
    setCurrentEndpoint(newEndpoint);
    if (selectedItem === 'Live Room') {
      openLiveRoomWindow();
    }
  };

  const openLiveRoomWindow = () => {
    const width = 800;
    const height = 450;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const liveRoomUrl = '/live-room.html'; // This will be a static HTML file
    
    window.open(
      liveRoomUrl,
      'LiveTradingRoom',
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  if (!currentEndpoint) {
    return <div>Loading...</div>;
  }

  const renderContent = () => {
    switch (selectedItem) {
      case 'Cycle':
        return <EChartsComponent key={`cycle-${currentEndpoint.url}`} apiEndpoint={currentEndpoint.url} chartTitle={currentEndpoint.title} />;
      case 'Expected Move':
        return <ExpectedMoveComponent 
        key={`expected-move-${currentEndpoint.url}`} 
        apiEndpoint={currentEndpoint.url} 
        chartTitle={currentEndpoint.title}
        symbol={currentEndpoint.symbol} />;

      case 'Gamma Profile':
        return <GammaProfileComponent 
          key={`gamma-profile-${currentEndpoint.url}`} 
          apiEndpoint={currentEndpoint.url} 
          chartTitle={currentEndpoint.title} 
          symbol={currentEndpoint.symbol}
        />;

      case 'Liquidity':
      return <LiquidityComponent 
        key={`liquidity-${currentEndpoint.url}`} 
        apiEndpoint={currentEndpoint.url} 
        chartTitle={currentEndpoint.title} 
      />;
      case 'Scanner':
        return <ScannerComponent 
          key={`scanner-${currentEndpoint.url}`} 
          apiEndpoint={currentEndpoint.url} />;
      case 'Live Room':
      return <div>Click the "Trading Room" button to open the Live Trading Room in a new window.</div>;
    // Add cases for other chart types
      default:
        return <div>Select a chart type</div>;
    }
  };

  return (
    <div className="flex flex-col h-full">
      <NavBar 
        endpoints={endpoints[selectedItem]} 
        currentEndpoint={currentEndpoint} 
        onEndpointChange={handleEndpointChange} 
      />
      <div className="flex-grow">
        {renderContent()}
      </div>
    </div>
  );
};

export default Dashboard;