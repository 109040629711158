import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { ArrowUp, ArrowDown } from 'lucide-react';

const CustomTooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef(null);

  return (
    <div 
      ref={triggerRef}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      className="relative inline-block"
    >
      {children}
      {isVisible && (
        <div
          className="absolute z-50 bg-black bg-opacity-80 text-white p-2 rounded shadow-lg max-w-xs text-sm"
          style={{
            top: '100%',
            left: '0',
            marginTop: '5px',
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

const ScannerComponent = ({ apiEndpoint }) => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const response = await fetch(apiEndpoint);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStocks(data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch stock data');
        setLoading(false);
      }
    };

    fetchStocks();
  }, [apiEndpoint]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }

    const sortedStocks = [...stocks].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setStocks(sortedStocks);
  };

  const SortableTableHead = ({ column, children }) => {
    const isSorted = sortColumn === column;
    let SortIcon = isSorted ? (sortDirection === 'asc' ? ArrowUp : ArrowDown) : null;

    return (
      <TableHead
        className="cursor-pointer text-xs font-normal text-gray-400"
        onClick={() => handleSort(column)}
      >
        <div className="flex items-center justify-between">
          {children}
          {SortIcon && <SortIcon className="ml-1 h-3 w-3" />}
        </div>
      </TableHead>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 bg-gray-900 text-white">
      <h2 className="text-xl font-bold mb-4">Stock Scanner</h2>
      <Table className="w-full">
        <TableHeader>
          <TableRow className="bg-gray-800">
            <SortableTableHead column="symbol">Symbol</SortableTableHead>
            <SortableTableHead column="companyName">Company Name</SortableTableHead>
            <SortableTableHead column="sector">Sector</SortableTableHead>
            <SortableTableHead column="industry">Industry</SortableTableHead>
            <SortableTableHead column="country">Country</SortableTableHead>
            <SortableTableHead column="marketCap">Market Cap</SortableTableHead>
            <SortableTableHead column="price">Price</SortableTableHead>
            <SortableTableHead column="change">Change</SortableTableHead>
            <SortableTableHead column="volume">Volume</SortableTableHead>
            <SortableTableHead column="scanner">Scanner</SortableTableHead>
            <SortableTableHead column="last_appeared">Last Appeared</SortableTableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {stocks.map((stock, index) => (
            <TableRow 
              key={stock.id}
              className={`${index % 2 === 0 ? 'bg-gray-900' : 'bg-gray-800'} hover:bg-gray-700`}
            >
              <TableCell>
                <CustomTooltip
                  content={
                    <div>
                      <p><strong>First Appeared:</strong> {stock.hover_details.first_appeared}</p>
                      <p><strong>First Price:</strong> ${stock.hover_details.first_price.toFixed(2)}</p>
                      <p><strong>Scanners:</strong> {stock.hover_details.scanners.join(', ')}</p>
                      <p><strong>Appearances:</strong> {stock.hover_details.appearance_count}</p>
                    </div>
                  }
                >
                  <span className="font-medium">{stock.symbol}</span>
                </CustomTooltip>
              </TableCell>
              <TableCell>{stock.companyName}</TableCell>
              <TableCell>{stock.sector}</TableCell>
              <TableCell>{stock.industry}</TableCell>
              <TableCell>{stock.country}</TableCell>
              <TableCell>${(stock.marketCap / 1e9).toFixed(2)}B</TableCell>
              <TableCell>${stock.price.toFixed(2)}</TableCell>
              <TableCell className={stock.change >= 0 ? 'text-green-500' : 'text-red-500'}>
                {stock.change.toFixed(2)}%
              </TableCell>
              <TableCell>{stock.volume.toLocaleString()}</TableCell>
              <TableCell>{stock.scanner}</TableCell>
              <TableCell>{stock.last_appeared}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ScannerComponent;