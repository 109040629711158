import React, { useState } from 'react';
import { Menu, X, BarChart2, ChartColumnStacked, Activity, Tv, Waves, Radar } from 'lucide-react';

const AppLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Cycle');
  const menuItems = [
    { name: 'Cycle', icon: BarChart2 },
    { name: 'Gamma Profile', icon: ChartColumnStacked },
    { name: 'Expected Move', icon: Activity },
    { name: 'Scanner', icon: Radar },
    { name: 'Liquidity', icon: Waves },
    { name: 'Live Room', icon: Tv },
  ];

  const toggleSidebar = () => setIsOpen(!isOpen);

  // Create a new child element with the selectedItem prop
  const childrenWithProps = React.Children.map(children, child => {
    // Checking if the child is a valid element before cloning
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { selectedItem });
    }
    return child;
  });

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className={`bg-gray-800 text-white w-45 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out`}>
        <button onClick={toggleSidebar} className="md:hidden absolute right-2 top-2 text-gray-300 hover:text-white">
          <X size={24} />
        </button>
        <nav>
          {menuItems.map((item) => (
            <button
              key={item.name}
              className={`block py-2.5 px-4 rounded transition duration-200 ${selectedItem === item.name ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
              onClick={() => setSelectedItem(item.name)}
            >
              <item.icon className="inline-block mr-2" size={20} />
              {item.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Navbar */}
        <header className="bg-white shadow-md">
          <div className="flex items-center justify-between p-4">
            <button onClick={toggleSidebar} className="md:hidden text-gray-600 hover:text-gray-800">
              <Menu size={24} />
            </button>
            <h2 className="text-xl font-semibold">{selectedItem}</h2>
          </div>
        </header>

        {/* Page Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200 p-6">
          {childrenWithProps}
        </main>
      </div>
    </div>
  );
};

export default AppLayout;