import { format } from 'date-fns';

const TIMEZONE_OFFSET = 7 * 60 * 60 * 1000; // 7 hours in milliseconds

export const processData = (chartData) => {
  return Object.keys(chartData.DateTime).map(key => ({
    time: chartData.DateTime[key], // Timestamp is in milliseconds already
    last: parseFloat(chartData.Last[key]),
    direction: parseFloat(chartData.Direction[key]),
    energy: parseFloat(chartData.Energy[key]),
  })).filter(item => !isNaN(item.last)).sort((a, b) => a.time - b.time);
};

export const createChartOption = (processedData, chartTitle) => {
  const xAxisData = processedData.map(item => {
    const date = new Date(item.time + TIMEZONE_OFFSET);
    return format(date, 'yyyy/MM/dd HH:mm');
  });

  const getLineStyle = (data) => {
    return data.map((value, index) => ({
      color: value >= 0 ? '#91cc75' : '#ee6666', // Green for positive, red for negative
      lineStyle: {
        color: value >= 0 ? '#91cc75' : '#ee6666',
        width: 2
      },
      itemStyle: {
        color: value >= 0 ? '#91cc75' : '#ee6666'
      }
    }));
  };

  return {
    title: {
      text: chartTitle,
      left: "center",
      textStyle: {
        color: "#eeeeee",
      },
    },
    backgroundColor: "rgba(51,51,51,1)",
    textStyle: {
      color: "#eeeeee",
    },
    grid: [
      {
        left: 60,
        right: 50,
        top: '10%',
        height: "50%",
      },
      {
        left: 60,
        right: 50,
        top: "65%",
        height: "15%",
      },
    ],
    color: [
      "#dd6b66", "#759aa0", "#e69d87", "#8dc1a9", "#ea7e53",
      "#eedd78", "#73a373", "#73b9bc", "#7289ab", "#91ca8c", "#f49f42",
    ],
    legend: {
      data: ['Last', 'Direction', 'Energy'],
      orient: "horizontal",
      left: 10,
      top: 0,
      textStyle: {
        color: "#eeeeee",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      formatter: function (params) {
        let tooltipText = `${params[0].axisValue}<br/>`;
        params.forEach(param => {
          tooltipText += `${param.seriesName}: ${param.value}<br/>`;
        });
        return tooltipText;
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        restore: {},
        saveAsImage: {},
      },
    },
    axisPointer: {
        link: [ {
            xAxisIndex: [0,1]
        } ]
    },
    dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 95,
          end: 100,
          top: '85%',
          height: 30, // Increased height
          backgroundColor: "rgba(47,69,84,0.8)", // Slightly more opaque
          dataBackground: {
            lineStyle: { color: '#777' },
            areaStyle: { color: '#444' }
          },
          fillerColor: "rgba(167,183,204,0.4)",
          handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
          handleSize: '110%',
          handleStyle: {
            color: "#a7b7cc",
          },
          textStyle: {
            color: "#eeeeee",
          },
          borderColor: "#999",
          xAxisIndex: [0, 1],
        },
        {
          type: "inside",
          xAxisIndex: [0, 1],
        },
      ],
    xAxis: [
      {
        type: 'category',
        gridIndex: 0,
        data: xAxisData,
        axisLabel: {
          color: "#eeeeee",
        },
      },
      {
        type: 'category',
        gridIndex: 1,
        data: xAxisData,
        axisLabel: {
          color: "#eeeeee",
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        gridIndex: 0,
        axisLabel: {
          color: "#eeeeee",
        },
      },
      {
        type: 'value',
        gridIndex: 1,
        axisLabel: {
          color: "#eeeeee",
        },
      }
    ],
    series: [
      {
        name: 'Last',
        data: processedData.map(item => item.last),
        type: 'line',
        smooth: true,
        xAxisIndex: 1,
        yAxisIndex: 1,
        lineStyle: { width: 2},
        itemStyle: {
            color: (params) => params.value >= 0 ? '#91cc75' : '#ee6666'
        }
      },
      {
        name: 'Direction',
        data: processedData.map(item => item.direction),
        type: 'line',
        smooth: true,
        xAxisIndex: 0,
        yAxisIndex: 0,
        lineStyle: { width: 2},
        itemStyle: {
            color: (params) => params.value >= 0 ? '#42e2b8' : '#eb8a90'
          },
        areaStyle:{}
      },
      {
        name: 'Energy',
        data: processedData.map(item => item.energy),
        type: 'bar',
        xAxisIndex: 0,
        yAxisIndex: 0,
        itemStyle: {
            color: (params) => params.value >= 0 ? '#d8d78f' : '#aa5042'
          }
      },
    ],
  };
};